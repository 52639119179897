module.exports = [{
      plugin: require('../node_modules/@smithc/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@vp/gatsby-theme-performance/gatsby-browser.js'),
      options: {"plugins":[],"applyCriticalCss":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
